const themestyle = {
    colors: {
      primary: "#263238",
      secondary: "#DE0093",
      background: "white",
      lightpink: "#F1D4FF",
      textPrimary: "#000000",
      textSecondary: "#636363",
      purple: "#651CB5",
      white: "#FFFFFF",
      fcolor: "#263238",
      black:"#101828",
      labelcolor: "#344054",
      formcolor: "rgba(0, 0, 0, 0.87)",
      formcolor1: "rgba(0, 0, 0, 0.23)",
      navbarcolor: "linear-gradient(275.81deg, #3326C3 2.78%, #DE0093 95.23%)",
      buttonText: "linear-gradient(90deg, #44C597 0%, #0177BF 100%)",
      circlecolor: "#F4EFFF",
      bgprimary:"#44C597",
      bgsecondary:"#0177BF",
      texttemplate:"#2B2B2B"
    },
    fontSizes: {
      small: "18px",
      medium: "24px",
      slarge:'36px',
      large: "32px",
      xlarge: "41px",
      xxlarge: "48px",
    },
    spacing: {
      small: "10px",
      medium: "20px",
      large: "30px",
      xlarge: "40px",
    },
    borderRadius: "100px",
  };
  export default themestyle;