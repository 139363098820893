import React, { useState } from "react";
import {
  Typography,
  Box,
  Button,
  Modal,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PheonixPreviewModalProps from "../interfaces/PheonixPreviewModalProps";
import { TEXT_MESSAGES } from "../const";

const PhoenixPreviewModal: React.FC<PheonixPreviewModalProps> = ({
  open,
  image,
  onClose,
  templatetitle,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="preview-modal"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: { xs: "90vw", sm: "80vw", md: "70vw" },
          height: { xs: "70vh", sm: "85vh", md: "80vh" },
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            display: "flex",
            justifyContent: "flex-end",
            padding: 2,
            bgcolor: "background.paper",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              color: "grey.500",
              "&:hover": {
                bgcolor: "background.default",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            flex: 1,
            minHeight: 0,
            overflowY: "auto",
            overflowX: "hidden",
            px: 4,
            pb: 4,
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "background.paper",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "grey.400",
              borderRadius: "4px",
            },
          }}
        >
          {image ? (
            <Box
              component="img"
              src={image}
              alt={templatetitle}
              sx={{
                width: "100%",
                height: "auto",
                display: "block",
                objectFit: "contain",
                margin: "0 auto",
              }}
            />
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "background.paper",
              }}
            >
              <Typography variant="h6" color="text.secondary">
                {TEXT_MESSAGES.PROGRESS_TEXT}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
export default PhoenixPreviewModal;
