export const TEXT_MESSAGES = {
  FEATURES: "Plan",
  BAIC: "Features",
  PRO: "Cost",
  Premium: "Premium",
  Custom: "Custom",
  Monthly: "Monthly",
  yearly: "Yearly",
  TEM_TXT: "Template sections",
  PREVIEW: "Preview",
  Feedback: "Customer Feedback",
  PROGRESS_TEXT: " In the Works! Coming Your Way!!",
  FBDESCRIPTION: `We’re dedicated to your success! Our team is here to support you
              every step of the way, providing solutions, insights, and tools to
              help you achieve your goals. From onboarding to ongoing support,
              we’re committed to ensuring you make the most out of our product!`,

  HEADER_BUTTON: "Request a Demo",
  ABOUT_TXT: "About Taxary",
  ABOUT_GET: "Get Started",
  FEATURES_TEXT: "Features",
  PRICE_TXT: "Pricing",
  PRICE_TITLE: "Our Price",
  PRICE_CONTENT:
    "Taxary makes buying and selling easy and user-friendly. Payments can be managed effortlessly, and you'll find the best prices with us.",
  PRICE_PER: "per person, per month",
  TEST_TXT: "Testimonials",
  CUSTOMER_TXT: "Trusted by Professionals",
  CUSTOMER_TITLE: "Our Customers",
  INTEREST_TXT: "If you'd like to join us, click here",
  INTEREST_BUTTON: "I'm Interested",
  INTEREST_MOBILE: "Sign up now!",
  INTEREST_MOBILE_TXT: "Sign up",
  PLEASE: "Please fill your details",
  BUSINESS_TYPE: "Business Type *",
  COMMENTS_ADDITIONAL: " Comments/Additional Requirements",
  HIDE_BUTTON: "Hide Reviews",
  SHOW_BUTTON: "View All Reviews",
  EMAIL_REG: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  FULL_NAME: "Full Name *",
  EMAIL: " Email Address *",
  ENQUIRY: "Enquiry Type",
  PHONE: "Phone Number *",
  POPULAR_TEXT: "Most Popular",
  SUBMIT:"Submit",
};
export const FOOTER_MESSAGES = {
  PRODUCT: "Product",
  RESOURCES: "Resources",
  COMPANY: "Company",
  LANGUAGE: "Language",
  FEATURES: "Features",
  PRICING: "Pricing",
  BLOG: "Blog",
  USER_GUIDES: "User guides",
  WEB: "Webinars",
  ABOUT: "About",
  JOINUS: "Join us",
  FOOTER_TXT: "PheonixSolutions Team",
  PRIVACY: " . Privacy",
  TERMS: " . Terms",
  SITE_MAP: " . Sitemap",
  Linkdin: "https://www.linkedin.com/company/pheonixsolutions",
  Youtube: "https://youtube.com",
  FB: "https://www.facebook.com/pheonixsolutionss",
  Pheonix_link: "https://pheonixsolutions.com/",
};
export const ERROR_MESSAGES = {
  MAIL_REQ: "Enter Mail ID",
  NAME_REQ: "Enter Name",
  NAME_VALID: "Name should not contain digits",
  MAIL_ERROR: "Enter a valid Mail",
  REQ_FIELDS: "Please fill all required fields correctly",
  BUSINESS_TYPE_REQ:"Enter Business Type",
  PHONE_NUMBER:"Enter Phone Number",
};
