import React, { useState } from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import PhoenixButton from "./PhoenixButton";
import themestyle from "../theme";
import banner from "../assets/banner.png";
import ContactModal from "./ContactModal";
import { PheonixProps } from "../interfaces/HomeProps";
import data from "../data/data.json";
import "../App.css";
import BannerBg from "../assets/bg.webp";
import Settings from "../assets/settingsgif.gif";
import { TypewriterEffectSmooth } from "./UI/TypeWriterEffect";
import { Cover } from "./UI/Cover";
import { HoverBorderGradient } from "./UI/HoverBorderGradient";
import { cn } from "../lib/utils";
import { Button } from "./UI/ButtonBorder";
import { TextRevealCard } from "./UI/TextRevealCard";
const About: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [modalOpen, setModalOpen] = useState(false);
  const handleActionClick = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const words = [
    {
      text: "Build",
    },
    {
      text: "awesome",
    },
    {
      text: "apps",
    },
    {
      text: "with",
    },
    {
      text: "Aceternity.",
      className: "text-blue-500 dark:text-blue-500",
    },
  ];
  return (
    <>
      <Box
        style={{
          position: "relative",
        }}
      >
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${BannerBg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            zIndex: -2,
          }}
        />
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background:
              "linear-gradient(90deg, rgba(68, 197, 151, 0.7) 0%, rgba(1, 119, 191, 0.7) 100%)",
            zIndex: -1,
          }}
        />
        <Grid
          container
          spacing={2}
          style={{
            padding: isSmallScreen ? "48px 32px" : "96px 64px",
          }}
        >
          <Grid item xs={12} sm={9} md={9}>
            {" "}
            <Typography
              variant="h6"
              style={{
                color: "#F3FFF4",
                fontFamily: "Lexend",
                fontWeight: 600,
                fontSize: isMobile ? "18px" : isTablet ? "18px" : "36px",
                marginBottom: themestyle.spacing.medium,
                marginTop: isMobile ? "18px" : isTablet ? "18px" : "36px",
              }}
            >
              {data.aboutUs.show} {data.aboutUs["14days"]}
            </Typography>
            <Typography
              variant="h4"
              style={{
                fontFamily: "Lexend",
                fontWeight: 600,
                fontSize: isMobile ? "32px" : isTablet ? "32px" : "68px",
                color: "#1B1C1D",
                alignItems: "center",
              }}
            >
              {data.aboutUs.howTo}
            </Typography>
            <Typography
              variant="h4"
              style={{
                fontFamily: "Lexend",
                fontWeight: 600,
                fontSize: isMobile ? "32px" : isTablet ? "32px" : "68px",
                color: "#1B1C1D",
                marginBottom: themestyle.spacing.medium,
              }}
            >
              {data.aboutUs.websitein}
            </Typography>
            <Typography
              style={{
                color: "#FFFFFF",
                fontSize: isMobile || isTablet ? "18px" : "24px",
                fontWeight: 600,
              }}
            >
              {!isMobile && !isTablet ? (
                <TypewriterEffectSmooth
                  words={[`${data.aboutUs.description}`]}
                  className={cn({
                    "text-[18px]": isMobile || isTablet,
                    "text-[24px]": !isMobile && !isTablet,
                  })}
                  fontSize={"24px"}
                  cursorClassName="#FFFFFF"
                />
              ) : (
                data.aboutUs.description
              )}
            </Typography>
            <Grid container justifyContent="center">
              <Button
                label="Contact us"
                className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800"
                onClick={handleActionClick}
                style={{
                  color: themestyle.colors.white,
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "18px",
                  borderRadius: themestyle.borderRadius,
                  marginTop: themestyle.spacing.medium,
                  width: "167px",
                  height: "50px",
                  background:
                    "linear-gradient(90deg, #44C597 0%, #0177BF 100%)",
                  "&:hover": {
                    color: "#0177BF",
                    background: themestyle.colors.white,
                  },
                }}
              >
                Contact us
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            container
            alignItems="flex-end"
            sx={{
              marginTop: isMobile ? "0px" : "100px",
            }}
          >
            <img
              src={banner}
              alt="Plan features illustration"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Grid>
          <ContactModal open={modalOpen} onClose={handleCloseModal} />
        </Grid>
      </Box>
    </>
  );
};

export default About;
