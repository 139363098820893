import React, { useState } from "react";
import {
 useMediaQuery,
  useTheme,
  Box,
  Typography,
  Modal,
} from "@mui/material";

import axios from "axios";
import { PheonixTextfield, PheonixSnackbar, PheonixButton } from "pheonixui";
import { api } from "../api";
import data from "../data/data.json";
import CloseIcon from "@mui/icons-material/Close";
import { ContactModalProps } from "../interfaces/ContactModalProps";
import { TEXT_MESSAGES, ERROR_MESSAGES } from "../const";

const ContactModal: React.FC<ContactModalProps> = ({ open, onClose }) => {
  const [formVisible, setFormVisible] = useState(true);
  const theme = useTheme();
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [business_type, setBusiness_type] = useState("");
  const [notes, setNotes] = useState("");
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [alertmessage, setalertmessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const resetFields = () => {
    setFullname("");
    setEmail("");
    setPhonenumber("");
    setNotes("");
    setBusiness_type("");
  };
  const validateField = (fieldLabel: string) => {
    const fieldValue = {
      "Full Name": fullname,
      "Email Address": email,
      "Business Type": business_type,
      "Phone Number": phonenumber,
    }[fieldLabel];

    switch (fieldLabel) {
      case "Email Address":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.MAIL_REQ,
          }));
        } else if (!TEXT_MESSAGES.EMAIL_REG.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.MAIL_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;

      case "Full Name":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.NAME_REQ,
          }));
        } else if (!/^[a-zA-ZÀ-ÖØ-öø-ÿ\s'-]*$/.test(fieldValue || "")) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.NAME_VALID,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Business Type":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.BUSINESS_TYPE_REQ,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;

      case "Phone Number":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.PHONE_NUMBER,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;

      default:
        break;
    }
  };

  const handleSubmit = async () => {
    setFormVisible(false);

    validateField("Full Name");
    validateField("Email Address");
    validateField("Business Type");
    validateField("Phone Number");

    const hasErrors = Object.values(fieldErrors).some((error) => error !== "");

    const isEmptyFields = !fullname || !email;

    if (hasErrors || isEmptyFields) {
      setalertmessage(ERROR_MESSAGES.REQ_FIELDS);
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 3000);
      return;
    }
    const query = `
    mutation webuildaddUser(
      $fullname: String!,
      $phone_number: String!,
      $email: String!,
      $notes: String!,
      $business_type: String!,
    ) {
      webuildaddUser(
        fullname: $fullname,
        phone_number: $phone_number,
        email: $email,
        notes: $notes,
        business_type: $business_type
      ) {
        success
        message
        reply
      }
    }
  `;

    const variables = {
      fullname: fullname,
      phone_number: phonenumber,
      email: email,
      notes: notes,
      business_type: business_type,
    };

    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });
      const { success, reply } = response.data.data.webuildaddUser;
      if (success) {
        resetFields();
        onClose();
        setalertmessage(reply);
        setSnackbarOpen(true);

        setTimeout(() => {
          setSnackbarOpen(false);
          onClose();
          setFullname("");
          setEmail("");
          setPhonenumber("");
          setNotes("");
          setBusiness_type("");
          setFieldErrors({});
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    
    <Modal open={open} onClose={onClose} disableAutoFocus>
      <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          overflow: "auto",
          p: 2,
        }}
      >
        <Box
          sx={{
            width: isMobile ? "100%" : "569px",
            maxHeight: isMobile ? "90vh" : "90vh",
            display: "flex",
            flexDirection: "column",
            background: "linear-gradient(135deg, #2CA9A5 10%, #067CBC 100%)",
            padding: "24px",
            gap: "16px",
            borderRadius: "10px",
            overflow: "auto",
            position: "relative",
            m: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              mb: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: isMobile ? "18px" : "24px",
                fontWeight: 700,
                fontFamily: "Inter",
                color: "#FFFFFF",
              }}
            >
              {TEXT_MESSAGES.PLEASE}
            </Typography>
            <CloseIcon
              sx={{
                color: "#FFFFFF",
                cursor: "pointer",
              }}
              onClick={onClose}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
              overflow: "visible",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: 2,
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#FFFFFF",
                    mb: 1,
                  }}
                >
                  {TEXT_MESSAGES.FULL_NAME}
                </Typography>
                <PheonixTextfield
                  type="text"
                  value={fullname}
                  error={Boolean(fieldErrors["Full Name"])}
                  helperText={fieldErrors["Full Name"]}
                  onChange={(e) => {
                    setFullname(e.target.value);
                    validateField("Full Name");
                  }}
                  size="small"
                  sx={{
                    width: "100%",
                    color: "#FFFFFF",
                    backgroundColor: "transparent",
                    border: "0px solid #FFFFFF",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#FFFFFF",
                        borderRadius: "10px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#FFFFFF",
                        borderRadius: "10px",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#FFFFFF",
                      borderRadius: "10px",
                      "&::selection": {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      },
                    },
                  }}
                />
              </Box>

              <Box sx={{ flex: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#FFFFFF",
                    mb: 1,
                  }}
                >
                  {TEXT_MESSAGES.EMAIL}
                </Typography>
                <PheonixTextfield
                  type="text"
                  value={email}
                  size="small"
                  error={Boolean(fieldErrors["Email Address"])}
                  helperText={fieldErrors["Email Address"]}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    validateField("Email Address");
                  }}
                  sx={{
                    width: "100%",
                    color: "#FFFFFF",
                    backgroundColor: "transparent",
                    border: "0px solid #FFFFFF",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#FFFFFF",
                        borderRadius: "10px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#FFFFFF",
                        borderRadius: "10px",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#FFFFFF",
                      "&::selection": {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      },
                    },
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: 2,
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#FFFFFF",
                    mb: 1,
                  }}
                >
                  {TEXT_MESSAGES.BUSINESS_TYPE}
                </Typography>
                <PheonixTextfield
                  type="text"
                  size="small"
                  error={Boolean(fieldErrors["Business Type"])}
                  helperText={fieldErrors["Business Type"]}
                  value={business_type}
                  onChange={(e) => {
                    setBusiness_type(e.target.value);
                    validateField("Business Type");
                  }}
                  sx={{
                    width: "100%",
                    color: "#FFFFFF",
                    backgroundColor: "transparent",
                    border: "0px solid #FFFFFF",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#FFFFFF",
                        borderRadius: "10px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#FFFFFF",
                        borderRadius: "10px",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#FFFFFF",
                      borderRadius: "10px",
                      "&::selection": {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "10px",
                      },
                    },
                  }}
                />
              </Box>

              <Box sx={{ flex: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#FFFFFF",
                    mb: 1,
                  }}
                >
                  {TEXT_MESSAGES.PHONE}
                </Typography>
                <PheonixTextfield
                  type="text"
                  size="small"
                  error={Boolean(fieldErrors["Phone Number"])}
                  helperText={fieldErrors["Phone Number"]}
                  value={phonenumber}
                  onChange={(e) => {
                    setPhonenumber(e.target.value);
                    validateField("Phone Number");
                  }}
                  sx={{
                    width: "100%",
                    color: "#FFFFFF",
                    backgroundColor: "transparent",
                    border: "0px solid #FFFFFF",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#FFFFFF",
                        borderRadius: "10px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#FFFFFF",
                        borderRadius: "10px",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#FFFFFF",
                        borderRadius: "10px",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#FFFFFF",
                      borderRadius: "10px",
                      "&::selection": {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "10px",
                      },
                    },
                  }}
                />
              </Box>
            </Box>

            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#FFFFFF",
                  mb: 1,
                }}
              >
                {TEXT_MESSAGES.COMMENTS_ADDITIONAL}
              </Typography>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                rows={4}
                style={{
                  width: "100%",
                  padding: "8px 12px",
                  backgroundColor: "transparent",
                  border: "1px solid #FFFFFF",
                  borderRadius: "8px",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  resize: "vertical",
                }}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <PheonixButton
                label={"Submit"}
                onClick={handleSubmit}
                sx={{
                  color: "black",
                  backgroundColor: "#FFFFFF",
                  fontSize: isMobile ? "12px" : "16px",
                  fontWeight: isMobile ? 500 : 600,
                  width: isMobile ? "124px" : "143px",
                  height: isMobile ? "28px" : "45px",
                  padding: "10px",
                  textTransform: "none",
                  borderRadius: "10px",
                  marginBottom: "30px",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                  },
                  "&:active": {
                    transform: "scale(0.98)",
                  },
                }}
              />
              <PheonixSnackbar
                vertical="top"
                horizontal="right"
                open={snackbarOpen}
                onClose={() => {
                  resetFields();
                  setSnackbarOpen(false);
                }}
                message={alertmessage}
                severity="error"
                backgroundColor="#353535"
                color="#ffffff"
                width="auto"
                height="62px"
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 400,
                  fontSize: { xs: "12px", sm: "16px" },
                  lineHeight: { xs: "18px", sm: "24px" },
                  mt: 8,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <PheonixSnackbar
     vertical="top"
     horizontal="right"
     open={snackbarOpen}
     onClose={() => {resetFields();
       setSnackbarOpen(false)}}
     message={alertmessage}
     severity="error"
      backgroundColor="#353535"
      color="#ffffff"
    width="auto"
     height="62px"
     sx={{
       fontFamily: "Inter",
       fontWeight: 400,
       fontSize: { xs: "12px", sm: "16px" },
       lineHeight: { xs: "18px", sm: "24px" },
       mt: 8,
     }}
   />
    </div>
    </Modal>
   
  );
};

export default ContactModal;
