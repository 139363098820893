import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "../assets/twitter.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ContactModal from "./ContactModal";
import "../style.css";
import data from "../data/FooterData.json";
import footerlogo from "../assets/footerlogo.png";

const PheonixFooter: React.FC = () => {
  const [sections, setSections] = useState(data.sections);
  const [addresses, setAddresses] = useState(data.addresses);
  const [follow, setfollow] = useState(data["Follow Us"]);
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLaptopSmall = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const currentYear = new Date().getFullYear();
  const dynamicCopyrightText = data.CopyrightText.replace(/\d{4}/, `${currentYear}`);

  const linkStyle = {
    color: "#FFFFFF",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      fontFamily: "Lexend",
    },
  };

  const iconComponents: Record<string, any> = {
    FacebookIcon: FacebookIcon,
    LinkedInIcon: LinkedInIcon,
    InstagramIcon: InstagramIcon,
  };

  const getIconComponent = (iconName: string) => {
    if (iconName === "TwitterIcon") {
      return (
        <img
          src={TwitterIcon}
          alt="Twitter"
          style={{ width: 18, height: 18, marginRight: "10px",marginTop:"2px" }}
        />
      );
    }
    const IconComponent = iconComponents[iconName];
    return IconComponent ? <IconComponent sx={{ marginRight: "10px" }} /> : null;
  };

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#000",
        color: "#ffff",
        padding: { xs: "20px 10px", sm: "40px 20px", md: "60px 40px" },
      }}
    >
      <Grid
        container
        spacing={4}
        sx={{
          textAlign: "left",
          justifyContent: { xs: "left", md: "space-around" },
        }}
      >
        {sections.map((section, index) => (
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            key={index}
            sx={{
              display: "block",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: "18px",
                fontFamily: "Lexend",
                fontWeight: "bold",
                marginBottom: isMobile ? "3px":"20px",
              }}
            >
              {section.heading}
            </Typography>

            {section.heading !== "Follow Us" &&
              section.items.map((item, idx) => (
                <div
                  key={idx}
                  style={{
                    cursor: "pointer",
                    color: "#FFFFFF",
                    fontFamily: "Lexend",
                  }}
                >
                  {item.text === "Contact" ? (
                    <span
                      onClick={handleOpen}
                      style={{ cursor: "pointer", color: "#FFFFFF" , marginLeft:isMobile ? "10px" : "0px"}}
                    >
                      <Box component="span" sx={linkStyle}>
                        {item.text}
                      </Box>
                    </span>
                  ) : (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <Box
                        component="span"
                        sx={linkStyle}
                        display="flex"
                        alignItems="center"
                        style={{ marginLeft:isMobile ? "10px" : "0px"}}
                      >
                        {item.text}
                      </Box>
                    </a>
                  )}
                </div>
              ))}
          </Grid>
        ))}
       <Grid
          item
          xs={12}
          sm={4}
          md={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          <Box
            component="img"
            src={footerlogo}
            alt="Logo"
            sx={{
              width: "153px",
              height: "121px",
              objectFit: "contain",
              textAlign: "center",
              margin: "0 auto",
              marginLeft: isMobile ? "inherit" :"0 auto"
            }}
          />
        </Grid>
        <ContactModal open={modalOpen} onClose={handleClose} />
      </Grid>

      <Grid
        container
        sx={{ justifyContent: { xs: "center", md: "flex-start", alignItems: "flex-start"} }}
      >
        <Grid item xs={12} sm={12} md={2}>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontSize: "18px",
              fontFamily: "Lexend",
              fontWeight: "bold",
              marginBottom: isMobile ? "10px" : isTablet ? "10px":"50px",
              marginTop: isSmallScreen ? "20px" : isTablet?"10px" : "0px",
              marginLeft: { xs: "0", md: "20px",  xl: "30px" },
            }}
          >
            {data.Address}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        item
       spacing={0}
        xs={6}
        sm={4}
        md={10}
        flexDirection="row"
        style={{
          justifyContent: "left",
          maxWidth: window.innerWidth >= 600 ? "80%" : "100%", 
              }}
      >
       {!isTablet && <Grid
          display="flex"
          flexDirection = {isSmallScreen ? "column" :"row"}
          flex="1"
          sx={{ justifyContent: { xs: "center", md: "flex-start", alignItems: "flex-start"} }}
          >
          {addresses.map((address, index) => (
            <Grid
              item
              xs={12}
              sm={4}
              key={index}
              sx={{  justifyContent: { xs: "center", md: "flex-start",lg: "left", alignItems: "flex-start"} , maxWidth:"100%"}}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  color: "#FFFFFF",
                  fontFamily: "Lexend",
                  marginBottom: "10px",
                  marginLeft: isMobile ? "5px" :{ xs: "0px", md: "20px", xl: "30px" },
                }}
              >
                {address.region}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Lexend",
                  fontSize: "16px",
                  marginLeft: isMobile ? "10px" :{ xs: "0px", md: "20px", xl: "30px" },
                }}
              >
                {address.content}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Lexend",
                  fontSize: "16px",
                  marginLeft:isMobile ? "10px" : { xs: "0px", md: "20px", xl: "30px" },
                }}
              >
                {address.content2}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Lexend",
                  fontSize: "16px",
                  marginLeft: isMobile ? "10px" :{ xs: "0px", md: "20px", xl: "30px" },
                }}
              >
                {address.city}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Lexend",
                  marginBottom: "10px",
                  fontSize: "16px",
                  marginLeft: isMobile ? "10px" :{ xs: "0px", md: "20px", xl: "30px" },
                }}
              >
                {address.phone}
              </Typography>
            </Grid>
          ))}
        </Grid>}
        {isTablet &&  <Grid spacing={2} container justifyContent="flex-start">
        {addresses.map((address, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                sx={{
                  textAlign: "left",
                  transition: "border-color 0.3s",
                }}
              >
                <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  color: "#FFFFFF",
                  fontFamily: "Lexend",
                  marginBottom: "10px",
                  marginLeft: isMobile ? "5px" :{ xs: "0px", md: "20px", xl: "30px" },
                }}
              >
                {address.region}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Lexend",
                  fontSize: "16px",
                  marginLeft: isMobile ? "10px" :{ xs: "0px", md: "20px", xl: "30px" },
                }}
              >
                {address.content}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Lexend",
                  fontSize: "16px",
                  marginLeft:isMobile ? "10px" : { xs: "0px", md: "20px", xl: "30px" },
                }}
              >
                {address.content2}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Lexend",
                  fontSize: "16px",
                  marginLeft: isMobile ? "10px" :{ xs: "0px", md: "20px", xl: "30px" },
                }}
              >
                {address.city}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Lexend",
                  marginBottom: "10px",
                  fontSize: "16px",
                  marginLeft: isMobile ? "10px" :{ xs: "0px", md: "20px", xl: "30px" },
                }}
              >
                {address.phone}
              </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>}
        {!isMobile && !isTablet && <Grid
          display="flex"
          flexDirection="column"
          style={{ fontWeight: "bold"}}
        >
          {" "}
          Follow Us
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              marginTop: "20px",
            }}
          >
            {follow.map((item, idx) => (
              <div
                key={idx}
                style={{
                  cursor: "pointer",
                  color: "#FFFFFF",
                 
                }}
              >
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <Box sx={linkStyle} display="flex" alignItems="center">
                    {"icon" in item && getIconComponent(item.icon)}
                  </Box>
                </a>
              </div>
            ))}
          </Box>
        </Grid>}
      </Grid>
      {(isMobile || isTablet) && <Grid
          display="flex"
          flexDirection="column"
          style={{ fontWeight: "bold" , marginLeft: isMobile ? "10px" : isTablet ? "0px": "20px", }}
        >
          {" "}
          Follow Us
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              marginTop: isMobile ? "8px" : "20px",
              
            }}
          >
            {follow.map((item, idx) => (
              <div
                key={idx}
                style={{
                  cursor: "pointer",
                  color: "#FFFFFF",
                 
                }}
              >
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <Box sx={linkStyle} display="flex" alignItems="center">
                    {"icon" in item && getIconComponent(item.icon)}
                  </Box>
                </a>
              </div>
            ))}
          </Box>
        </Grid>
}
      <Divider sx={{ backgroundColor: "#F4F4F4", marginY: 2, marginTop: isMobile ? "30px" : isTablet ? "20px":  12 }} />
      {isMobile &&  <Grid
        item
        xs={12}
        sx={{
          textAlign: isSmallScreen ? "center" : "center",
          fontWeight: "normal"        
        }}
      >
        <Typography
          sx={{
            color: "#FFFFFF",
            fontSize: "16px",
            fontFamily: "Lexend",
            marginTop: 2,
            marginBottom: isSmallScreen ? 0 : -5,
            marginLeft: { xs: "0", md: "20px", xl: "30px" },
          }}
        >
         {data.CopyrightFirst}<br/> {data.CopyrightSecond}
        </Typography>
      </Grid>
}
{!isMobile &&  <Grid
        item
        xs={12}
        sx={{
          textAlign: isSmallScreen ? "center" : "center",
          fontWeight: "normal"        
        }}
      >
        <Typography
          sx={{
            color: "#FFFFFF",
            fontSize: "16px",
            fontFamily: "Lexend",
            marginTop: 2,
            marginBottom: isSmallScreen ? 0 : -5,
            marginLeft: { xs: "0", md: "20px", xl: "30px" },
          }}
        >
          {dynamicCopyrightText}
        </Typography>
      </Grid>
}
    </Box>
  );
};

export default PheonixFooter;
