import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import PhoenixCard from "./PheonixCard";
import PheonixBox from "./PheonixBox";
import themestyle from "../theme";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { PheonixProps } from "../interfaces/HomeProps";
import data from "../data/data.json";
import Group from "../assets/Group.png";
import scalability from "../assets/scalability.png";
import Branding from "../assets/Branding.png";
import Domain from "../assets/Domain.png";
import commerce from "../assets/commerce.png";
import Performance from "../assets/Performance.png";
import FocusCards from "./UI/FocusCards";

const images: any = {
  limited_customization: Group,
  scalability_issues: scalability,
  Branding: Branding,
  Domain: Domain,
  commerce: commerce,
  Performance: Performance,
};

const Product: React.FC<PheonixProps> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isIphone = useMediaQuery("(min-width: 375px) and (max-width: 670px)");
  return (
    <Grid
      container
      spacing={2}
      style={{
        textAlign: "center",
        justifyContent: "center",
        background: `linear-gradient(90deg, ${themestyle.colors.bgprimary} 0%, ${themestyle.colors.bgsecondary} 100%)`,
      }}
    >
      <Grid
        item
        style={{
          padding: isMobile ? "23px 34px" : "23px 104px",
          marginLeft: isMobile ? "18px" : "0px",
        }}
      >
        <Typography
          variant="h4"
          style={{
            fontFamily: "Lexend",
            fontWeight: 600,
            fontSize: isSmallScreen
              ? themestyle.fontSizes.large
              : themestyle.fontSizes.slarge,
            color: themestyle.colors.black,
            marginBottom: themestyle.spacing.medium,
            textAlign: "center",
            marginTop: themestyle.spacing.xlarge,
          }}
        >
          {data.product.intro}
        </Typography>
        <Typography
          variant="h6"
          style={{
            color: themestyle.colors.white,
            fontSize: themestyle.fontSizes.small,
            fontWeight: 600,
            marginBottom: themestyle.spacing.large,
            width: "100%",
            lineHeight: "27px",
            fontFamily: "Lexend",
            textAlign: isSmallScreen ? "center" : "center",
          }}
        >
          {data.product.ownTitle}
        </Typography>
        <Typography
          variant="h6"
          style={{
            color: themestyle.colors.black,
            fontSize: themestyle.fontSizes.medium,
            fontWeight: 700,
            marginBottom: themestyle.spacing.large,
            width: "100%",
            lineHeight: "27px",
            fontFamily: "Lexend",
          }}
        >
          {data.product.introTitle}
        </Typography>

        <FocusCards cards={data.product.cards} />
      </Grid>
    </Grid>
  );
};
export default Product;
