import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Modal,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TEXT_MESSAGES } from "../const";
import { PhoenixCardProps } from "../interfaces/PheonixCardProps";
import PhoenixPreviewModal from "./PheonixPreviewModal";
import template from "../assets/template.png";
import caketemplate from "../assets/CakeShop.png";
import dancetemplate from "../assets/DanceStudio.png";
import fitnesstemplate from "../assets/gym.png";
import childtemplate from "../assets/ChildCare.png";
import cartemplate from "../assets/car.png";
import agritemplate from "../assets/Agritemplatesecond.png";
import agrisecondtemplate from "../assets/Agritemplate.png";
import themestyle from "../theme";

interface ImageMap {
  [key: string]: string;
}

const images: ImageMap = {
  technoit: template,
  cakeshopheader: caketemplate,
  Danceheader: dancetemplate,
  Fitnessheader: fitnesstemplate,
  childcareheader: childtemplate,
  carheader: cartemplate,
  agriheader: agritemplate,
  agriheaderthird: agrisecondtemplate,
};

const PhoenixCard: React.FC<PhoenixCardProps> = ({
  title,
  description,
  description1,
  mediaUrl,
  style,
  showButton,
  buttonText,
  bulletPoints,
  amount,
  sx,
  type,
  image,
  templatetitle,
  onButtonClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLaptop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");

  const getTemplateNameFromUrl = (imageUrl: string): string => {
    const fileName = imageUrl.split("/").pop() || "";

    const templateName = fileName.split(".")[0];
    return templateName;
  };

  const handleImageClick = (imageUrl: string) => {
    const templateName = getTemplateNameFromUrl(imageUrl);
    setSelectedTemplate(templateName);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedTemplate("");
  };
  return (
    <Card
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        borderRadius: "16px",
        textAlign: "left",
        width:
          style?.width ||
          (isSmallScreen && type !== "template" ? "90%" : "422px"),
        height: style?.height || isSmallScreen ? "271px" : "224px",
        position: "relative",
        margin: isSmallScreen ? "0 auto" : "",
        ...style,
      }}
      sx={{
        ...sx,
      }}
    >
      <CardContent>
        {mediaUrl && (
          <Box
            component="img"
            src={mediaUrl}
            alt={title}
            width="50"
            height="50"
            mb={1}
          />
        )}
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          style={{
            color: isHovered && type !== "template" ? "white" : "#252B42",
            fontWeight: 700,
            fontFamily: "Lexend",
          }}
        >
          {title}
        </Typography>
        {description && <Typography variant="body2">{description}</Typography>}
        {description1 && (
          <Typography
            variant="body2"
            style={{ color: themestyle.colors.purple, fontFamily: "Lexend" }}
          >
            {description1}
          </Typography>
        )}

        {amount && (
          <Box
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              background: themestyle.colors.navbarcolor,
              marginTop: "10px",
              borderRadius: title === "Basic" ? "38px" : "50%",
              width: title === "Basic" ? "90px" : "60px",
              height: title === "Basic" ? "40px" : "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid transparent",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                backgroundColor: themestyle.colors.circlecolor,
                borderRadius: title === "Basic" ? "38px" : "50%",
                width:
                  title === "Basic" ? "calc(120% - 8px)" : "calc(100% - 4px)",
                height:
                  title === "Basic" ? "calc(100% - 2px)" : "calc(100% - 4px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body2"
                component="div"
                style={{ width: "30px" }}
              >
                {amount}
              </Typography>
            </Box>
          </Box>
        )}
        {bulletPoints && (
          <ul style={{ height: "280px" }}>
            {bulletPoints.map((point, index) => (
              <li key={index} style={{ textAlign: "start", fontSize: "14px" }}>
                {point}
              </li>
            ))}
          </ul>
        )}
        <Box
          sx={{
            position: "relative",
            width: "fit-content",
            "&:hover .preview-overlay": {
              opacity: 1,
            },
          }}
        >
          {image && (
            <>
              <Box
                component="img"
                src={image}
                alt={templatetitle}
                width="250px"
                height="167px"
                borderRadius="5px"
                mb={1}
              />
              <Box
                className="preview-overlay"
                onClick={() => handleImageClick(image)}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  opacity: 0,
                  transition: "all 0.3s",
                  height: "100%",
                  cursor: "pointer",
                  zIndex: 2,
                  marginBottom: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgba(0, 0, 0, 0.6)",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    width: "142px",
                    height: "30px",
                    color: "white",
                    padding: "6px 20px",
                    borderRadius: "5px",
                    fontSize: "14px",
                    fontWeight: 500,
                    border: "1px solid white",
                    textAlign: "center",
                    alignItems: "center",
                    fontFamily: "Lexend",
                  }}
                >
                  {TEXT_MESSAGES.PREVIEW}
                </Box>
              </Box>
            </>
          )}

          <PhoenixPreviewModal
            open={isModalOpen}
            onClose={handleClose}
            templatetitle={templatetitle}
            image={images[selectedTemplate]}
          />
        </Box>

        {templatetitle && (
          <Box sx={{ position: "relative" }}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Lexend",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                textAlign: "left",
                position: "absolute",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                width: "100%",
                minHeight: "24px",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              title={templatetitle}
            >
              {templatetitle}
            </Typography>
          </Box>
        )}

        {showButton && buttonText && (
          <Box
            mt={2}
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              textAlign: "center",
              mb: 2,
            }}
          >
            <Button
              variant="contained"
              onClick={onButtonClick}
              style={{
                textTransform: "none",
                background: `linear-gradient(90deg, ${themestyle.colors.bgprimary} 0%, ${themestyle.colors.bgsecondary} 100%)`,
                borderRadius: "6px",
                width: isSmallScreen ? "90%" : isLaptop ? "170px" : "240px",
                height: "32px",
              }}
            >
              {buttonText}
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
export default PhoenixCard;
